@font-face {
  font-family: 'Roobert';
  font-weight: 400;
  font-style: normal;
  src: url('./fonts/roobert-regular-webfont.woff2') format('woff2'),
    url('./fonts/roobert-regular-webfont.woff') format('woff');
}

@font-face {
  font-family: 'Roobert';
  font-weight: 500;
  font-style: normal;
  src: url('./fonts/roobert-medium-webfont.woff2') format('woff2'),
    url('./fonts/roobert-medium-webfont.woff') format('woff');
}


@font-face {
  font-family: 'Roobert';
  font-weight: 600;
  font-style: normal;
  src: url('./fonts/roobert-semibold-webfont.woff2') format('woff2'),
    url('./fonts/roobert-semibold-webfont.woff') format('woff');
}


@font-face {
  font-family: 'Roobert';
  font-weight: 700;
  font-style: normal;
  src: url('./fonts/roobert-bold-webfont.woff2') format('woff2'),
    url('./fonts/roobert-bold-webfont.woff') format('woff');
}
